<template>
  <div class="container" id="home">
    <div class="row">
      <maintop1 ref="newmesstop" @click_pro_left="click_pro_home" />
      <div
        :class="
          (content_w == 'content4' || content_w == 'content10') && flag_nav
            ? 'main_fix_offset right_ann'
            : (content_w == 'content4' || content_w == 'content10') && !flag_nav
            ? 'main_fix_width right_ann_close'
            : flag_nav
            ? 'main_fix_offset col-xl-10'
            : 'main_fix_width col-xl-12'
        "
      >
        <div class="row d-flex justify-content-center p-0 m-0">
          <maintop2 ref="getbalance_home" />
          <Footer />
        </div>
        <div class="row mx-auto">
          <banner class="mb-3 home_banner" />
        </div>
        <hr class="m-0" />
        <marquee />
        <div>
          <div class="game_list" v-show="content_w != 'share'">
            <div class="list_card tab-title p-2 mx-xl-auto">
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content3'"
                :class="{ list_hover: content_w == 'content3' }"
              >
                <img
                  src="@/assets/img/main/ic2_pro.png"
                  :title="$t('faq_5').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content4'), (type = 'casino')"
                :class="{ list_hover: content_w == 'content4' }"
              >
                <img
                  src="@/assets/img/main/ic2_slot.png"
                  :title="$t('text_igaming').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content14'), (type = 'GUESS')"
                v-if="code != null"
                :class="{ list_hover: content_w == 'content14' }"
              >
                <img
                  src="@/assets/img/main/favorites.png"
                  :title="$t('text_favorites').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content15'"
                :class="{ list_hover: content_w == 'content15' }"
              >
                <img
                  src="@/assets/img/main/main_sport.png"
                  :title="$t('text_sport').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content18'"
                :class="{ list_hover: content_w == 'content18' }"
              >
                <img
                  src="@/assets/img/main/icon_casino.png"
                  :title="$t('home_text6').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content16'), (type = 'V8')"
                :class="{ list_hover: content_w == 'content16' }"
              >
                <img
                  src="@/assets/img/main/card.png"
                  :title="$t('text_poker').toUpperCase()"
                />
              </div>
              <!--  <div
                class="list_name col-4 main_img"
                @click="content_w = 'content0'"
                v-if="code == null"
                :class="{ list_hover: content_w == 'content0' }"
              >
                <img
                  src="@/assets/img/main/mini.png"
                  :title="$t('text_free').toUpperCase()"
                />
              </div>-->
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content17'), (type = 'a1')"
                :class="{ list_hover: content_w == 'content17' }"
              >
                <img
                  src="@/assets/img/main/icon_slot_new.png"
                  :title="$t('text_igaming').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content19'), (type = 'fish')"
                :class="{ list_hover: content_w == 'content19' }"
              >
                <img
                  src="@/assets/img/main/icon_fish.png"
                  :title="$t('text_fish').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content1'), (type = 'T1')"
                :class="{ list_hover: content_w == 'content1' }"
              >
                <img
                  src="@/assets/img/main/ic2_minigame.png"
                  :title="$t('text_mini').toUpperCase()"
                />
              </div>

              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content11'), (type = 'JILI')"
                :class="{ list_hover: content_w == 'content11' }"
              >
                <img src="@/assets/img/main/jili.png" title="JILI" />
              </div> -->

              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content12'), (type = 'PG')"
                :class="{ list_hover: content_w == 'content12' }"
              >
                <img src="@/assets/img/main/pg.png" title="PG" />
              </div> -->

              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content13'), (type = 'PP')"
                :class="{ list_hover: content_w == 'content13' }"
              >
                <img src="@/assets/img/main/pp.png" title="PP" />
              </div> -->

              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content2'), (type = 'slot')"
                :class="{ list_hover: content_w == 'content2' }"
              >
                <img
                  src="@/assets/img/main/ic2_hot.png"
                  :title="$t('text_hot').toUpperCase()"
                />
              </div> -->

              <!-- <div
                class="list_name col-4 main_img"
                @click="content_w = 'content5'"
                :class="{ list_hover: content_w == 'content5' }"
              >
                <img
                  src="@/assets/img/main/1420338.png"
                  :title="$t('text_rank').toUpperCase()"
                />
              </div> -->
              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content6'), (type = 'shoot')"
                :class="{ list_hover: content_w == 'content6' }"
              >
                <img
                  src="@/assets/img/main/shoot.png"
                  :title="$t('text_shoot').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content7'), (type = 'mini')"
                :class="{ list_hover: content_w == 'content7' }"
              >
                <img
                  src="@/assets/img/main/minigame.png"
                  :title="$t('text_mini').toUpperCase()"
                />
              </div> -->
              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content8'), (type = 'virtual')"
                :class="{ list_hover: content_w == 'content8' }"
              >
                <img
                  src="@/assets/img/main/virtual.png"
                  :title="$t('text_virtual').toUpperCase()"
                />
              </div> -->
              <!-- <div
                class="list_name col-4 main_img"
                @click="content_w = 'content9'"
                :class="{ list_hover: content_w == 'content9' }"
              >
                <img
                  src="@/assets/img/main/ic2_free.png"
                  :title="$t('text_free').toUpperCase()"
                />
              </div> -->

              <!-- <div class="list_name col-4 main_img" @click="content_w = 'content10'"
                :class="{ list_hover: content_w == 'content10' }">
                <img src="@/assets/img/main/invest.png" :title="$t('text_invest').toUpperCase()" />
              </div> -->
            </div>
          </div>

          <div v-show="content_w == 'content0'" class="mobi_margin">
            <freegame />
          </div>

          <div v-show="content_w == 'content1'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4"
                v-lazy="'http://gameweb.metaversement.io/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content2'" class="mobi_margin">
            <div class="live_casino tab-inner">
              <div class="live_casino_card">
                <div class="game_slider_card">
                  <div class="col-12 game_logo_img fw-bolder my-3">
                    {{ $t("home_text5") }}
                  </div>
                  <div
                    v-for="l in content2_slot"
                    :key="l"
                    class="filter_card"
                    @click="
                      game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                    "
                  >
                    <div
                      class="filter_card1"
                      v-lazy:background-image="
                        'http://gameweb.metaversement.io/' + l.image
                      "
                    ></div>
                    <div class="mask position-absolute top-0 start-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content3'" class="mobi_margin">
            <promotionview :index="getanumber()"></promotionview>
          </div>

          <div v-if="content_w == 'content4'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div class="col-12 game_logo_img fw-bolder my-3">
                      {{ $t("home_text6") }}
                    </div>
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="
                          'http://gameweb.metaversement.io/' + l.image
                        "
                      ></div>
                      <div class="mask position-absolute top-0 start-0"></div>
                    </div>
                    <div class="col-12">
                      <img
                        src="@/assets/img/game/all/live_bg.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'BG', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_yeebet.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'YB', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_wm.png"
                        :class="css_list"
                        @click="game('web', 'casino', '0', 'WM', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_evo.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'EVO', 'casino')"
                      />
                      <!-- <img
                        src="@/assets/img/game/all/live_xg.png"
                        :class="css_list"
                        @click="game('web', 'casino', 'xg006', 'XG', 'casino')"
                      /> -->
                      <img
                        src="@/assets/img/game/all/live_ag.png"
                        :class="css_list"
                        @click="game('', '1', '0', 'AG', 'casino')"
                      />
                      <!-- <img
                        src="@/assets/img/game/all/live_pp.png"
                        :class="css_list"
                        @click="game('web', 'casino', '101', 'PP', 'casino')"
                      /> -->
                      <img
                        src="@/assets/img/game/all/live_sexyb.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'SEXYB', 'casino')"
                      />
                    </div>
                    <div class="col-12 game_logo_img fw-bolder my-3">
                      {{ $t("home_text7") }}
                    </div>
                    <div class="col-12">
                      <img
                        v-for="l in allinslot"
                        :key="l"
                        :src="require('@/assets/img/allinslot/' + l + '.png')"
                        :class="css_list"
                        @click="set_gametype('A1,' + l.toUpperCase())"
                      />
                      <img
                        v-for="l in slot_game"
                        :key="l"
                        :src="
                          require('@/assets/img/game/all/slot_' + l + '.png')
                        "
                        :class="css_list"
                        @click="set_gametype(l.toUpperCase())"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="@/assets/img/main/sponser_cbf.jpg"
                class="right_img_ann offset-2 d-none col-2"
                @click="link('service')"
              />
            </div>
          </div>

          <div v-if="content_w == 'content5'" class="mobi_margin">
            <rankview></rankview>
          </div>

          <div v-show="content_w == 'content6'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-3 col-4"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content7'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-3 col-4"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content8'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-4 col-6"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content9'" class="mobi_margin">
            <freegame9 />
          </div>

          <div v-if="content_w == 'content10'" class="mobi_margin">
            <investview />
            <!-- <financeview /> -->
            <giftview />
            <img
              src="@/assets/img/main/sponser_cbf.jpg"
              class="right_img_ann offset-2 d-none col-2"
              @click="link('service')"
            />
          </div>

          <div v-show="content_w == 'share'" class="mobi_margin">
            <share
              :shareurl="shareurl"
              :share_more_content="share_more_content"
            />
          </div>

          <div v-show="content_w == 'content11'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card c-pointer"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="
                          'http://gameweb.metaversement.io/' + l.image
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content12'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4 col-xl-2"
                v-lazy="'http://gameweb.metaversement.io/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content13'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4 col-xl-2"
                v-lazy="'http://gameweb.metaversement.io/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content14'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div
                    class="game_slider_card"
                    v-if="list != null && list != ''"
                  >
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="l.iconName"
                      ></div>
                      <div class="mask position-absolute top-0 start-0"></div>
                    </div>
                  </div>

                  <div v-else class="text-center mt-3">
                    <p>{{ $t("nodata") }}</p>
                    <p>{{ $t("text_favorites2") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content16'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card c-pointer"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="
                          'http://gameweb.metaversement.io/' + l.image
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content15'" class="mobi_margin">
            <mainsport_sb @watch_newWindowurl="home_newWindowurl" />
          </div>

          <div v-if="content_w == 'content17'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div class="col-12 game_logo_img fw-bolder my-3">
                      {{ $t("home_text7") }}
                    </div>
                    <div class="col-12">
                      <img
                        v-for="l in allinslot"
                        :key="l"
                        :src="require('@/assets/img/allinslot/' + l + '.png')"
                        :class="css_list"
                        @click="set_gametype('A1,' + l.toUpperCase())"
                      />
                      <img
                        v-for="l in slot_game"
                        :key="l"
                        :src="
                          require('@/assets/img/game/all/slot_' + l + '.png')
                        "
                        :class="css_list"
                        @click="set_gametype(l.toUpperCase())"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="@/assets/img/main/sponser_cbf.jpg"
                class="right_img_ann offset-2 d-none col-2"
                @click="link('service')"
              />
            </div>
          </div>

          <div v-if="content_w == 'content18'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div class="col-12">
                      <img
                        src="@/assets/img/game/all/live_bg.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'BG', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_yeebet.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'YB', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_wm.png"
                        :class="css_list"
                        @click="game('web', 'casino', '0', 'WM', 'casino')"
                      />
                      <img
                        src="@/assets/img/game/all/live_evo.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'EVO', 'casino')"
                      />
                      <!-- <img
                        src="@/assets/img/game/all/live_xg.png"
                        :class="css_list"
                        @click="game('web', 'casino', 'xg006', 'XG', 'casino')"
                      /> -->
                      <img
                        src="@/assets/img/game/all/live_ag.png"
                        :class="css_list"
                        @click="game('', '1', '0', 'AG', 'casino')"
                      />
                      <!-- <img
                        src="@/assets/img/game/all/live_pp.png"
                        :class="css_list"
                        @click="game('web', 'casino', '101', 'PP', 'casino')"
                      /> -->
                      <img
                        src="@/assets/img/game/all/live_sexyb.png"
                        :class="css_list"
                        @click="game('web', 'casino', '', 'SEXYB', 'casino')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content19'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card c-pointer"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="
                          'http://gameweb.metaversement.io/' + l.image
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <dw_rank v-show="content_w != 'content5'" />
        <footertwo />
      </div>
    </div>
    <prewindow></prewindow>
    <bighead
      @flag_val="flag_big_fun"
      v-if="flag_head && code != null"
    ></bighead>
    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import banner from "./Home/Banner.vue";
import promotionview from "./Promotion.vue";
import rankview from "./rank.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import bighead from "./Home/big_head_img.vue";
import share from "./share.vue";
import investview from "./invest.vue";
// import financeview from "./finance.vue";
import newWindow from "./iframe.vue";
import giftview from "./gift.vue";
import dw_rank from "./dw_rank.vue";
import freegame from "./free_game.vue";
import freegame9 from "./free_game_9.vue";
import mainsport_sb from "./main_sport_sb.vue";
import { mapActions, mapGetters } from "vuex";
import marquee from "./marquee.vue";
export default {
  name: "Home",
  data() {
    return {
      content_w: "content0",
      code: Cookies.get("code"),
      userid: "Dongbet",
      device: "",
      gametype: "",
      gamekind: "",
      gameid: "",
      mode: "",
      type: "",
      css_list: "col-lg-4 col-6 mb-2 px-2 c-pointer",
      utype: localStorage.getItem("utype"),
      flag_head: false,
      vip: 0,
      follow: 0,
      shareurl: "",
      big_head_img_set: localStorage.getItem("big_logo"),
      newWindowurl: "",
      slot_game: [
        // "pg",
        "fachai",
        "ps",
        "ka",
        "funta",
        "rich",
        // "pp",
        "CQ9",
        "V8",
        // "jili",
      ],
      allinslot: [
        "AINSWORTH",
        "AMATIC",
        "APEX",
        "APOLLO",
        "ARISTOCRAT",
        "EGT",
        "gclub",
        "igrosoft",
        "igt",
        "microgaming",
        "netent",
        "novomatic",
        "pragmatic",
        "quickspin",
        "scientific_games",
        "vegas",
        "wazdan",
      ],
      share_more_content: [],
    };
  },
  components: {
    Footer,
    maintop1,
    maintop2,
    banner,
    promotionview,
    rankview,
    footertwo,
    Prewindow,
    bighead,
    share,
    investview,
    // financeview,
    newWindow,
    giftview,
    dw_rank,
    freegame,
    freegame9,
    mainsport_sb,
    marquee,
  },
  computed: {
    ...mapGetters(["service", "list", "flag_nav"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    content2_slot: function () {
      if (this.content_w == "content2" && this.list)
        return this.list.slice(0, 40);
      else return [];
    },
  },
  methods: {
    ...mapActions(["getService", "getGameList"]),
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = "";
          break;
      }
      window.open(url);
    },
    gamelist: function (t) {
      var action_val =
        t != "shoot" && t != "mini" && t != "virtual" && t != "a1"
          ? "GameLobby"
          : "";
      let param = {
        action: action_val,
        body: {
          gametype: t,
          type: "all",
          uid: localStorage.getItem("uid"),
        },
      };
      this.getGameList(param);
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (
            type.toUpperCase() == "TCG" ||
            type.toUpperCase() == "SB" ||
            type.toUpperCase() == "SEXYB"
          ) {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    getanumber: function () {
      return Math.random();
    },
    set_gametype: function (e) {
      localStorage.setItem("g", e);
      this.$router.push("/slot");
    },
    flag_big_fun: function (e, imgval) {
      this.flag_head = e;
      localStorage.setItem("big_logo", imgval);
      this.big_head_img_set = imgval;
    },
    change_con_ig: function () {
      this.content_w = "content4";
      this.type = "casino";
    },
    click_pro_home: function (e) {
      this.content_w = e;
    },
    vip_follow: function (txt) {
      let param = {
        action: "vip_follows",
        body: {
          uid: txt,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (parseFloat(res["vip"]) / 1000000000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000000000 + "B";
        else if (parseFloat(res["vip"]) / 1000000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000000 + "M";
        else if (parseFloat(res["vip"]) / 1000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000 + "K";
        else this.vip = res["vip"];

        if (parseFloat(res["follow"]) / 1000000000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000000000 + "B";
        else if (parseFloat(res["follow"]) / 1000000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000000 + "M";
        else if (parseFloat(res["follow"]) / 1000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000 + "K";
        else this.follow = res["follow"];
      });
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.shareurl = res.url;
          this.share_more_content = res.share_more_content;
        }
      });
    },
    nologin_bigimg: function () {
      let param = {
        action: "Member_unlogin",
        body: {
          uid: this.userid,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.big_head_img_set = res.logo;
        }
      });
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
    home_newWindowurl(e) {
      if (e == "close") {
        this.$refs.getbalance_home.getBalance();
        this.$refs.newmesstop.getnewmess();
      }
    },
  },
  created() {
    if (localStorage.getItem("first") == "first" && this.code != null) {
      this.$store.commit("ADDCARD", true);
    } else {
      this.$store.commit("ADDCARD", false);
    }
    this.getService();
    if (this.code != null) {
      this.userid = localStorage.getItem("uid");
      this.share();
    } else {
      this.userid =
        localStorage.getItem("introducer") == null ||
        localStorage.getItem("introducer") == ""
          ? "Dongbet"
          : localStorage.getItem("introducer");

      if (this.userid != "Dongbet") this.nologin_bigimg();
      else this.big_head_img_set = "logo_big";
    }
    this.vip_follow(this.userid);

    if (this.Id == "share") this.content_w = "share";
    else this.content_w = "content3";
  },
  watch: {
    type: function (e) {
      this.gamelist(e);
    },
    newWindowurl: function () {
      if (this.newWindowurl == null || this.newWindowurl == "") {
        this.home_newWindowurl("close");
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
#login #home .offset-xl-2 {
  margin-left: 0;
}

#login #home .navbar,
#login #home .collaspe {
  display: none;
}
</style>
