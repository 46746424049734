<template>
  <div class="my-2">
    <Vue3Marquee class="text-white fw-bold" :pause-on-hover="true">
      {{
        msg
      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </Vue3Marquee>
  </div>
</template>
<script>
import { Vue3Marquee } from "vue3-marquee";
export default {
  name: "marquee",
  computed: {},
  data() {
    return {
      msg: "",
    };
  },
  methods: {
    marquee: function () {
      let param = {
        action: "message",
        body: {},
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.msg = res.msg;
      });
    },
  },
  watch: {},
  created() {
    this.marquee();
  },
  components: {
    Vue3Marquee,
  },
};
</script>
